import styled from "styled-components"
import React from "react"

const Summary = ({total = 20222}) => {


	return (
		<Wrapper>
			<PriceBody>
				<TitleSide>
					<PriceTitle>
						ИТОГО
					</PriceTitle>
					{/*<PriceDescription>
						В месяц.
					</PriceDescription>*/}
				</TitleSide>
				<InfoSide>
					<PriceDescription>

					</PriceDescription>
				</InfoSide>
				<PriceSide>
					{total.toLocaleString('ru-RU')}<span>₽</span>
				</PriceSide>
			</PriceBody>
		</Wrapper>
	)
}

export default Summary

const Wrapper = styled.div`
  position: sticky;
  bottom: 0;
  display: flex;
  flex: 1;
  flex-direction: column;
  background: #181C20;
  border-top: 1px solid #2e2e46;
`
const PriceBody = styled.div`
  display: flex;
  flex: 1;
`
const TitleSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: .67;
  line-height: 24px;
  padding: 32px 0 32px 20px;
`
const PriceTitle = styled.div`
  font-size: 23px;
  font-weight: 600;
  line-height: 24px;

  sup {
    margin-left: 10px;
  }
`
const PriceDescription = styled.div`
  margin-top: 10px;
  font-size: 14px;
  color: #ccced1;
`

const InfoSide = styled.div`
  display: flex;
  flex-direction: column;
  flex: .22;
  padding-left: 32px;
  justify-content: center;
  font-size: 19px;
  font-weight: 500;
`
const PriceSide = styled.div`
  display: flex;
  flex: .14;
  align-items: center;
  justify-content: center;
  background-color: #1f1f1f;
  font-size: 19px;
  font-weight: 500;
  color: #34C759;

  span {
    font-size: 15px;
    margin-left: 2px;
    margin-top: 3px;
  }
`
