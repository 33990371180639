import styled from "styled-components"

const ContentTitle = ({centered = true, top = 110, children}) => {

	return (
		<Wrapper centered top={top}>
			<Content centered={centered}>
				{children}
			</Content>
		</Wrapper>
	)
}

export default ContentTitle

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
  margin-top: ${props => props.top}px;
  width: 100%;
  justify-content: center;
  align-items: ${props => props.centered ? 'center' : 'flex-start'};
`

const Content = styled.h2`
  display: flex;
  flex-direction: column;
  width: 100%;
  max-width: 1240px;
  padding-inline: 20px;
  align-items: ${props => props.centered ? 'center' : 'unset'};
  text-align: ${props => props.centered ? 'center' : 'unset'};
`
