import ContentBlock from "../../components/UI/ContentBlock"
import Card from "../../components/UI/Card"
import ContentHeader from "../../components/UI/ContentHeader"
import ContentTitle from "../../components/UI/ContentTitle"
import ContentDescription from "../../components/UI/ContentDescription"
import Icon from "../../components/Icon/Icon"
import {faLeaf} from "@fortawesome/pro-solid-svg-icons"
import ReadyToStart from "../../components/UI/ReadyToStart";

const MonitoringScreen = () => {

	return (
		<>
			<ContentHeader
				media="/assets/img/png/123.png"
				orientation="left"
				title="Мониторинг"
				description="Местоположение, время посещений, скоростной и температурный режимы, контроль топлива, открытие ворот вне выгрузки  — все в одном месте."
			/>

			<ContentBlock>
				<Card title="Терминал" image="/assets/img/png/digital-map.png">
					<p>
						Настоящий ситуационный центр. Под рукой вся необходимая информация.
					</p>
					<p>
						С помощью быстрого поиска и аналитики, логист моментально получает полную информацию о точке,
						водителе или транспортном средстве.
					</p>
				</Card>

				<Card title="Журнал событий" right image="/assets/img/png/digital-map.png">
					<p>Мы собираем каждое действие пользователей и бережно его храним в течении трёх лет.</p>
					<p>Для удобного просмотра все данные сгруппированны по маршрутным листам и точкам.</p>
				</Card>

				<Card title="Поддержка филиалов" image="/assets/img/png/digital-map.png">
					<p>
						Перемещайтесь между вашими филиалами одним кликом мыши.
					</p>
					<p>
						Каждый филиал может иметь индивидуальные настройки пользователей, список водителей и ТС,
						часового пояса, систем мотивации и много другого.
					</p>
				</Card>

				<Card title="Архив маршрутных листов" right image="/assets/img/png/digital-map.png">
					<p>Каждый маршрут мы просчитываем по дорогам, поэтому у нас есть детальная информация о них:</p>
					<p>
						<ul>
							<li>Время выезда со склада</li>
							<li>Плановое время посещения каждой точки</li>
							<li>Расстояния всех отрезков</li>
							<li>Время возвращения на склад</li>
						</ul>
					</p>
				</Card>
			</ContentBlock>

			<ContentBlock bright top={110}>
				<ContentTitle>
					Внешние устройства и датчики
				</ContentTitle>
				<ContentDescription>
					Ознакомьтесь с дополнительными возможностями при установке внешних датчиков.
					Свяжитесь с нами, чтобы узнать, как получить датчики и их установку абсолютно бесплатно.
				</ContentDescription>

				<Card title="GPS мониторинг" right
					  image="https://assets-global.website-files.com/6050a76fa6a633d5d54ae714/6580c0514d17595923cca058_img_4.webp">
					<p>
						Является базовым устройством для подключения дополнительных внешних датчиков.
					</p>
					<p>
						Обладает точным
						местоположением, высокой частотой обновления, автоматическим проставлением выполнения заданий,
						прогнозом возвращения на склад и многим другим.
					</p>
				</Card>

				<Card title="Контроль расхода топлива"
					  image="https://assets-global.website-files.com/6050a76fa6a633d5d54ae714/6580bd54bf8e2497f51aa2fe_img_2.webp">
					<p>
						Подключите датчик уровня топлива (ДУТ) для точного измерения расхода, а мы оповестим вас о
						сливах горючего прямо на карте.
					</p>
				</Card>

				<Card title="Температурный режим"
					  image="https://assets-global.website-files.com/6050a76fa6a633d5d54ae714/6580bd5447a0ca3830755bac_img_3.webp"
					  right>
					<p>
						Контролируйте температурный режим в рефрижераторах во время доставки охлажденных и замороженных
						продуктов.
					</p>
					<p>
						Для сотрудников склада, помимо стандартной информации о готовности автомобиля к загрузке, будет
						доступна информация о текущей температуре в кузове.
					</p>
				</Card>

				<Card title="Датчик открытия ворот"
					  image="https://assets-global.website-files.com/6050a76fa6a633d5d54ae714/6580bd5447a0ca3830755bac_img_3.webp"
				>
					<p>Мы оповестим о любом открытии ворот вне зон загрузки и загрузки автомобиля.</p>
				</Card>

				<Card title="CAN шина" right
					  image="https://assets-global.website-files.com/6050a76fa6a633d5d54ae714/6580bd5447a0ca3830755bac_img_3.webp"
				>
					<p>
						Для определенных моделей автомобилей мы можем получать точные данные о текущем пробеге и
						техническом состоянии.
					</p>
					<p>
						Эта информация используется для оповещения о предстоящем ТО в блоке <a>технического
						обслуживаения
						авто
					</a>.
					</p>
				</Card>

				<Card title={<><Icon name={faLeaf} size={32} color="#7cb305" right={5}/> ECO Drive</>}
					  image="https://assets-global.website-files.com/6050a76fa6a633d5d54ae714/6580bd54261a6f56031675a8_img_5.webp"
				>
					<p>
						Мы оцениваем стиль вождения каждого водителя и устанавливаем индекс экономичности, используя
						встроенные датчики ускорения и скорости.
					</p>
				</Card>
			</ContentBlock>
			<ContentBlock>
				<ReadyToStart/>
			</ContentBlock>
		</>
	)
}

export default MonitoringScreen
