import React from "react"
import styled from "styled-components"
import SectionTitle from "./SectionTitle"
import SectionItem from "./SectionItem"
import {faMugHot} from "@fortawesome/pro-solid-svg-icons"

const Services = ({fines, changeFines}) => {

	const priceFine = {
		head: ['Количество авто', 'Стоимость, мес'],
		data: [
			{range: '1 − 15', price: '1000 за авто'},
			{range: '16 − 30', price: 4000},
			{range: '31 − 100', price: 6000},
			{range: '101 − 200', price: 9000},
			{range: '201 − 300', price: 12000},
			{range: '301 − 400', price: 14000},
			{range: '401 − 600', price: 18000},
			{range: '> 600', price: 'обсуждается'}
		]
	}

	return (
		<Wrapper>
			<SectionTitle title='Сервисы' sup="опционально"
						  icon={{backgroundColor: '#BF5AF2', component: faMugHot, size: 25}}/>
			<SectionItem
				title='Мотивация'
				price='от 10 000'
				priceDescription='Индивидуально'
				description='Данный сервис настраивается и просчитывается индивидуально.'
			/>

			<SectionItem
				title='Штрафы ГИБДД'
				slider={{
					min: 0,
					max: 600,
					marks: {50: '50', 150: '150', 300: '300', 500: '500'},
					step: 1,
					onChange: changeFines,
					value: fines.count
				}}
				info={fines.count}
				price={fines.price}
				priceDetail={priceFine}
				priceDescription={'Количество автомобилей'}
				description='Укажите количество авто в парке, которые требуют автоматической проверки на штрафы.'
			/>
		</Wrapper>
	)
}

export default Services

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
`
