import styled from "styled-components"
import Card from "../../components/UI/Card"
import ContentBlock from "../../components/UI/ContentBlock"
import {Button, Space} from "antd"
import Icon from "../../components/Icon/Icon"
import {faApple, faGooglePlay} from "@fortawesome/free-brands-svg-icons"
import {AndroidFilled} from "@ant-design/icons"
import {useState} from "react"
import ModalDownloadApp from "./component/ModalDownloadApp"

const RoutingScreen = () => {

	const [selectedStore, setSelectedStore] = useState(null)

	return (
		<>
			<ContentBlock>
				<HeaderWrapper>
					<InfoWrapper>
						<h1>Мобильное приложение</h1>
						<p>
							Современное мобильное приложение для водителей и курьеров. Ежеминутная аналитика дорожной
							обстановки, автоматическая оптимизация маршрута, календарь поездок, мотивация, денежные
							начисления и многое другое.
						</p>
						<ButtonWrapper>
							<Button onClick={() => setSelectedStore('appStore')} icon={<Icon name={faApple} size={24}/>}
									shape="round" size="large">AppStore</Button>
							<Button onClick={() => setSelectedStore('googlePlay')}
									icon={<Icon name={faGooglePlay} size={20}/>}
									shape="round" size="large">Google
								Play</Button>
							<Button onClick={() => setSelectedStore('appGallery')}
									icon={<AndroidFilled style={{fontSize: 20}}/>}
									shape="round"
									size="large">AppGallery</Button>
							<Button onClick={() => setSelectedStore('apk')}
									icon={<AndroidFilled style={{fontSize: 20}}/>}
									shape="round"
									size="large">APK</Button>
						</ButtonWrapper>
					</InfoWrapper>
					<MediaWrapper>
						<img src="/assets/img/png/mobile-app/mobile-app.png"/>
					</MediaWrapper>
				</HeaderWrapper>
			</ContentBlock>

			<Menu/>

			<ContentBlock>
				<Card title="Маршрутный лист" image="/assets/img/png/mobile-app/route.png">
					{/*<Card title="Электронный маршрутный лист"
						  image="https://assets-global.website-files.com/6050a76fa6a633d5d54ae714/651e8557b730b61ff947f814_homepage__search.png">*/}
					<p>
						Водителю больше не нужно "прикидывать" свой рабочий день. Мы уже просчитали все метрики
						маршрута, и в случае изменения дорожной ситуации моментально скорректируем данные.
					</p>
				</Card>

				<Card title="Информация о точках" right image="/assets/img/png/digital-map.png">
					<p>
						Еще до выезда на точку, водитель будет иметь полную информацию о ней и сможет одним нажатием
						кнопки перейти в навигатор с готовым маршрутом.
					</p>
				</Card>


				<Card title="Номенклатура" image="/assets/img/png/mobile-app/cargos.png">
					<p>
						К каждой точке прилагаются накладные с полным перечнем товаров для сдачи или приёмки.
					</p>

					<p>
						Если товары не были сданы полностью, водитель может отметить это и указать причину, чтобы
						логистика немедленно узнала об этом.
					</p>
				</Card>

				<Card top={70} right title="Чек-листы и задания" image="/assets/img/png/mobile-app/checklist.png">
					<p>
						Чек-листы представляют собой набор действий, которые водитель должен выполнить для успешного
						старта/завершения точки или маршрутного листа.
					</p>
				</Card>

				<Card title="Фотофиксация" image="/assets/img/png/mobile-app/photo.png">
					<p>
						Простая и удобная функция, позволяющая водителю сделать фотографию на месте и добавить к ней
						комментарий.
					</p>
				</Card>

				<Card title="Сканер штрих-кодов" image="/assets/img/png/mobile-app/barcode.png" transparent right>
					<p>
						Отсканируйте коробку, пакет или паллету, чтобы убедиться, что передаете клиенту именно то, что
						указано в документах.
					</p>
					<p>
						Приложение в реальном времени подсветит те коробки, которые надо передать адресату.
					</p>
				</Card>

				<Card title="Штрафы ГИБДД" image="/assets/img/png/mobile-app/fines.png">
					<p>
						Мы автоматически получаем штрафы из ГИДД, и сразу привизываем их к водителю, который
						нарушил.
					</p>
					<p>
						А при подключении системы мотивации, мы автоматически удержим сумму штрафа со счёта водителя.
					</p>
				</Card>

				<Card title="Календарь поездок" image="/assets/img/png/mobile-app/calendar.png" right>
					<p>
						Одна из самых популярных функций нашего приложения.
					</p>
					<p>
						Водителю всегда доступен архив маршрутных листов за любой день с информацией об адресах, пробеге
						и времени работы.
					</p>
				</Card>

				{/*<Card title="Мотивация" image="/assets/img/png/digital-map.png">
					<p>Все вычисления происходят на наших серварах. Это позволяет
						маршрутизировать из любой точки мира с одинакомым комфортом.</p>
					<p>Для работы вам подойдёт любое, хоть немного современное устройство с браузером.</p>
				</Card>*/}
			</ContentBlock>

			<ModalDownloadApp selectedStore={selectedStore} setSelectedStore={setSelectedStore}/>
		</>
	)
}

export default RoutingScreen

const HeaderWrapper = styled.div`
  display: flex;
  margin-top: 80px;
  background: radial-gradient(49.14% 49.14% at 50% 48%, rgba(0, 57, 117, 0.70) 0%, rgba(0, 73, 151, 0.00) 100%);
`
const InfoWrapper = styled.div`
  display: flex;
  flex: 0.5;
  flex-direction: column;
  justify-content: center;
`
const MediaWrapper = styled.div`
  display: flex;
  justify-content: center;
  flex: 0.5;

  img {
    max-height: 650px;
  }
`
const ButtonWrapper = styled(Space)`
  margin-top: 20px;

  button {
    min-width: 140px;
    display: flex;
    align-items: center;
    justify-content: center;
  }
`
const Menu = styled.div`
  position: sticky;
  box-shadow: rgba(18, 18, 18, 1) 0px -10px 10px;
  top: 62px;
  width: 100%;
  background: #161A1D;
  height: 49px;
`
