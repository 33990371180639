import styled from "styled-components"
import {Col, Row, Space} from "antd"
import ContentBlock from "../../components/UI/ContentBlock"
import Icon from "../../components/Icon/Icon"
import {faEgg, faPeopleGroup, faRocket, faUserRobot} from "@fortawesome/pro-thin-svg-icons"
import Card from "../../components/UI/Card"
import ContentHeader from "../../components/UI/ContentHeader"

const RoutingScreen = () => {

	return (
		<>
			<ContentHeader
				media="/assets/video/zazaza.mp4"
				orientation="left"
				title="Маршрутизация"
				description="Быстрый, мощный и удобный инстурмент для автоматической и ручной маршрутизации."
			/>
			{/*<VideoWrapper>
				<ReactPlayer
					playing={true}
					url={"/assets/video/driver-mobile-app.mp4"}
					muted={true}
					loop={true}
					width={"100%"}
					height={"100%"}
				/>
				<HeaderDescription>
					<h1>Маршрутизация</h1>
					<p>Быстрый, мощный и удобный инстурмент для автоматической и ручной маршрутизации.</p>
					<ButtonWrapper>
						<Button type="primary" shape="round" size="large">Попробовать бесплатно</Button>
						<Button shape="round" size="large">Связаться с нами</Button>
					</ButtonWrapper>
				</HeaderDescription>
			</VideoWrapper>*/}


			<ContentBlock top={110}>
				<Row gutter={30}>
					<Col span={6}>
						<Icon name={faRocket} color="#057AFD" size={60}/>
						<CardTitle>
							Работа в браузере
						</CardTitle>
						<CardDescription>
							Маршрутизируйте в браузере из любой точки мира.
						</CardDescription>
					</Col>
					<Col span={6}>
						<Icon name={faUserRobot} color="#057AFD" size={60}/>
						<CardTitle>
							Автоматически или руками
						</CardTitle>
						<CardDescription>
							Всегда есть возможность подкорректировать результат вручную.
						</CardDescription>
					</Col>
					<Col span={6}>
						<Icon name={faEgg} color="#057AFD" size={60}/>
						<CardTitle>
							Простой интерфейс
						</CardTitle>
						<CardDescription>
							Обучение занимает не более часа. Персональные онлайн консультации.
						</CardDescription>
					</Col>
					<Col span={6}>
						<Icon name={faPeopleGroup} color="#057AFD" size={60}/>
						<CardTitle>
							Групповое использование
						</CardTitle>
						<CardDescription>
							Маршрутизируйте параллельно с коллегами.
						</CardDescription>
					</Col>
				</Row>

				<Card title="Интерактивная карта" image="/assets/img/png/digital-map.png">
					<p>
						Выделите точки на карте и получите готовые маршрутные листы всего в два клика. Вы можете выбрать
						автоматическое или ручное создание.
					</p>
					<p>
						Логист в любой момент может изменить маршрутный лист по своему усмотрению, так как он может
						обладать информацией, которой мы не располагаем.
					</p>
				</Card>

				<Card title="Просчёт маршрутов" right image="/assets/img/png/digital-map.png">
					<p>
						Мы просчитываем каждый маршрут, учитывая дорожные условия, поэтому у нас есть детальная
						информация о них:
						<ul>
							<li>Время выезда со склада</li>
							<li>Плановое время посещения каждой точки</li>
							<li>Расстояния всех участков маршрута</li>
							<li>Время возвращения на склад</li>
						</ul>
					</p>
				</Card>

				<Card top={70} title="Определение координат"
					  image="/assets/img/png/digital-map.png">
					<p>Мы автоматически определим координаты ваших адресов и покажем их водителям.</p>
					<p>Сложный подъезд к зданию? Не проблема, укажите точное место парковки, и мы запомним его.</p>
				</Card>

				<Card title="График отгрузки ТС" right image="/assets/img/png/digital-map.png">
					<p>
						После маршрутизации вы получите детальный график и порядок отгрузки транспорта со склада.
					</p>
					<p>
						Используйте эти данные, чтобы равномерно распределить нагрузку и избежать очередей в пиковые
						часы.
					</p>
				</Card>

				<Card title="Быстродействие" image="/assets/img/png/digital-map.png">
					<p>
						Все вычисления выполняются на наших серверах, что позволяет маршрутизировать из любой точки мира
						с одинаковым комфортом.
					</p>
					<p>Для работы вам подойдет любое современное устройство с браузером.</p>
				</Card>

				<Card title="Групповое использование" image="/assets/img/png/digital-map.png" right>
					<p>Любое действие одного логиста моментально отображается в интерфейсе другого, что позволяет
						нескольким специалистам работать одновременно.</p>
				</Card>
			</ContentBlock>


		</>
	)
}

export default RoutingScreen

const VideoWrapper = styled.div`
  position: relative;
  display: flex;
  align-self: center;
  align-items: center;
  justify-content: center;
  width: 100%;
  max-width: 1880px;
`
const HeaderDescription = styled.div`
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  position: absolute;
  left: 0;
  right: 0;
  bottom: 0;
  top: 0;
  background-color: rgba(0, 0, 0, 65%);
  color: #FFFFFF;

  p {
    font-size: 20px;
  }
`
const ButtonWrapper = styled(Space)`
  margin-top: 60px;

  button {
    min-width: 230px;
  }
`
const CardTitle = styled.h5`
  margin-top: 20px;
`
const CardDescription = styled.div`
  color: #8b94a1;
  margin-top: 5px;
  line-height: 24px;
  font-weight: 300;
`
