import React, {useMemo} from "react"
import styled from "styled-components"
import LogoLight from "../../assets/svg/logo-light.svg"
import LogoDark from "../../assets/svg/logo-dark.svg"
import {Link, useLocation, useNavigate} from "react-router-dom"
import {Button, theme} from "antd"


const Header = () => {

	const navigate = useNavigate()
	const location = useLocation()
	const currentTheme = theme.useToken().token.theme

	const menu = useMemo(() => {
		return [
			/*{path: "", title: "Главная"},*/
			{path: "mobile-app", title: "Мобильное приложение"},
			{path: "monitoring", title: "Мониторинг", transparent: true},
			{path: "routing", title: "Маршрутизация", transparent: true},
			{path: "services", title: "Сервисы"},
			{path: "price", title: "Тарифы"},
			{path: "contact", title: "Контакты"}
		]
	}, [])

	const transparent = useMemo(() => {
		return menu.findIndex(menu => menu.path === location.pathname.substring(1) && menu.transparent) >= 0
	}, [location.pathname])

	/*const handleScroll = (e) => {
		console.log(e)
	}

	window.addEventListener('scroll', handleScroll)*/

	return (
		<Fixed transparent={transparent}>
			<Wrapper>
				<Logo onClick={() => navigate('/')}>
					<img src={currentTheme === 'dark' || transparent ? LogoDark : LogoLight} height={25}/>
				</Logo>
				<Navigation>
					{
						menu.map((item, index) => (
							<MenuItem
								//href={'/' + item.path}
								key={"menu-item-" + index}
								onClick={() => navigate('/' + item.path)}
							>
								{item.title}
							</MenuItem>
						))
					}
				</Navigation>
				<Login>
					<Link to="https://app.diefly.ru">
						<Button type="primary" shape="round">Вход в аккаунт</Button>
					</Link>
				</Login>
			</Wrapper>
		</Fixed>
	)
}

export default Header

const Fixed = styled.div`
  display: flex;
  position: sticky;
  top: 0px;
  width: 100%;
  max-width: 1920px;
  z-index: 10;
  background-color: rgba(0, 0, 0, ${props => props.transparent ? 40 : 100}%);
  backdrop-filter: blur(10px);
  -webkit-backdrop-filter: blur(10px);
`
const Wrapper = styled.div`
  display: flex;
  flex: 1;
  padding: 15px 20px;
  align-items: center;
`
const Logo = styled.div`
  display: flex;
  cursor: pointer;
`
const Navigation = styled.div`
  display: flex;
  flex: 1;
  align-items: center;
  justify-content: center;
`
const MenuItem = styled.span`
  padding-inline: 20px;
  font-size: 14px;
  cursor: pointer;
  text-shadow: rgb(0, 0, 0) 0px 1px 1px;
`
const Login = styled.div`
  display: flex;
`
