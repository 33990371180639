import styled from "styled-components"

const Card = ({
				  title,
				  bright = false,
				  badge = null,
				  image = null,
				  right = false,
				  top = 110,
				  transparent = false,
				  children
			  }) => {

	return (
		<Wrapper top={top} bright={bright} right={right} transparent={transparent}>
			<Info>
				{badge ? <Badge color={badge.color}>{badge.text}</Badge> : null}
				<Title>{title}</Title>
				<Description>
					{children}
				</Description>
			</Info>
			<Media>
				{image ? <img src={image} height="100%"/> : null}
			</Media>
		</Wrapper>
	)
}

export default Card

const Wrapper = styled.div`
  display: flex;
  flex-direction: ${props => props.right ? 'row-reverse' : 'row'};
  flex: 1;
  grid-column-gap: 70px;
  grid-row-gap: 24px;
  justify-content: space-between;
  margin-top: ${props => props.top}px;
  align-items: center;
  background: ${props => props.transparent ? 'transparent' : props.bright ? props.theme.colorBgCard : "unset"};
  padding: 16px;
  border-radius: 16px;

  a {
    svg {
      transition: margin-left 0.15s ease;
    }

    :hover {
      svg {
        margin-left: 5px;
      }
    }
  }
`
const Info = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  height: 100%;
  padding-left: ${props => !props.right ? 40 : 0}px;
  padding-right: ${props => props.right ? 40 : 0}px;
`
const Badge = styled.div`
  display: flex;
  align-self: flex-start;
  margin-bottom: 20px;
  border-radius: 4px;
  letter-spacing: 1.4px;
  padding: 6px 8px;
  line-height: 14px;
  font-weight: 400;
  font-size: 14px;
  text-transform: uppercase;
  background-color: ${props => props.color};
  color: #FFFFFF;
`
const Title = styled.h3`
  font-size: 32px;
  letter-spacing: -.02em;
  line-height: 1.3em;
  margin: 0;
  padding: 0;
`
const Description = styled.div`
  color: ${props => props.theme.colorTextDescription}
`
const Media = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1.3;
  border-radius: 16px;
  overflow: hidden;
  max-height: 700px;

  img {
    overflow-clip-margin: content-box;
    overflow: clip;
    max-width: 100%;
    display: inline-block;
  }
`
