import Title from "../../components/UI/Title"
import ContentBlock from "../../components/UI/ContentBlock"
import Description from "../../components/UI/Description"
import {Button, Space} from "antd"
import styled from "styled-components"
import PartnerScreen from "./component/PartnerScreen"
import Carousel from "./component/Carousel"
import Card from "../../components/UI/Card"
import Icon from "../../components/Icon/Icon"
import {faArrowRight} from "@fortawesome/pro-regular-svg-icons"
import {faLeaf} from "@fortawesome/pro-solid-svg-icons"

const HomeScreen = () => {

	return (
		<>
			<Title top={120}>Цифровая трансформация вашей лостики</Title>

			<Description top={25}>
				Самые современные решения для оптимизации доставки, взаимодействия с водителями и повышения уровня
				клиентского сервиса.
				<ButtonWrapper>
					<Button type="primary" shape="round" size="large">Попробовать бесплатно</Button>
					{/*<Button shape="round" size="large">Связаться с нами</Button>*/}
				</ButtonWrapper>
			</Description>

			<Carousel/>

			<ContentBlock centered top={140}>
				<PartnerScreen/>
			</ContentBlock>

			<ContentBlock top={140}>
				<Card title="Мобильное приложение" top={0}
					  image="/assets/img/png/mobile-app/zazaza.png"
				>
					<p>
						Самое совершенное и удобное мобильное приложение на рынке.
					</p>
					<p>
						Маршрутные листы, архив поездок, взаиморасчеты, штрафы ГИБДД — всё это и многое другое доступно
						водителю.
					</p>

					<a>Узнать больше <Icon name={faArrowRight}/></a>
				</Card>

				<Card title="Мониторинг" right
					  badge={{text: <><Icon name={faLeaf} style={{marginRight: 5}}/> ECO Drive</>, color: '#7cb305'}}
					  image="https://assets-global.website-files.com/6050a76fa6a633d5d54ae714/651ed0c196f40c3d249e615f_img-upd.png">
					<p>
						Местоположение, время посещений, открытие ворот вне выгрузки, контроль топлива, скоростной и
						температурный режимы — все в одном
						месте.
					</p>
					<p>
						Новая система ECO Drive поможет оценивать персональное качество и экономичность вождения.
					</p>

					<a>Узнать больше <Icon name={faArrowRight}/></a>
				</Card>

				<Card title="Маршрутизация"
					  image="https://assets-global.website-files.com/6050a76fa6a633d5d54ae714/651c33e1f4ae9f57c5299ac8_img-ev.png">
					<p>
						Мощный и одновременно простой инструмент для маршрутизации точек.
					</p>
					<p>
						Фильтруй, выделяй и маршрутизируй точки прямо на карте, а система поможет подобрать водителя и
						транспортное средство.
					</p>
					<a>Узнать больше <Icon name={faArrowRight}/></a>
				</Card>

				<Card title="Аналитика" right
					  image="https://diefly.ru/static/media/analytics.5fb8a5262eea1b1854d0.png">
					<p>
						Помимо стандартных отчётов, мы собираем персональные отчёты для ваших сотрудников с разными
						срезами
						данных.
					</p>
				</Card>

				<Card bright title="Штрафы ГИБДД" badge={{color: '#007AFC', text: 'Новое'}}
					  image="https://assets-global.website-files.com/6050a76fa6a633d5d54ae714/651ed0c196f40c3d249e615f_img-upd.png">
					<p>
						Мы автоматически собираем штрафы из ГИБДД.
					</p>
					<p>
						Система сама определит водителя, который нарушил, и отправит ему уведомление в мобильное
						приложение.
					</p>
				</Card>

				<Card title="Система мотивации водителей" right
					  image="https://assets-global.website-files.com/6050a76fa6a633d5d54ae714/613684fbdf4d656abc2d6004_dash(navigation).jpeg">
					<p>
						Мы обрабатываем огромное количество событий и показателей работы ваших водителей.
					</p>
					<p>
						Создавайте категории водителей с разными коэффициентами вознаграждений, а система автоматически
						будет их перемещать.
					</p>
					<a>Узнать больше <Icon name={faArrowRight}/></a>
				</Card>

				<></>

				<Card title="Ссылка для клиента" image="https://diefly.ru/static/media/client.d998c96e203eae735702.png">
					<p>
						Позвольте клиенту самому отслеживать статус доставки. Просто активируйте опцию, и система в
						нужный
						момент отправит получателю ссылку на карту.
					</p>
				</Card>
			</ContentBlock>
		</>
	)
}

export default HomeScreen

const ButtonWrapper = styled(Space)`
  margin-top: 32px;
  align-self: center;

  button {
    min-width: 230px;
  }
`
