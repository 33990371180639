import styled from "styled-components"
import React from "react"

const Footer = () => {

	return (
		<Wrapper>
			<Navigation>
				<NavigationColumn>
					<ColumnTitle>Приложение</ColumnTitle>
					<a href="/mobile-app#route">Маршрутный лист</a>
					<a href="/mobile-app#points-info">Информация о точках</a>
					<a href="/mobile-app#routing-optimization">Оптимизация объезда</a>
					<a href="/mobile-app#ckeck-lists">Чек-листы</a>
					<a href="/mobile-app#invoice">Товарная накладная</a>
					<a href="/mobile-app#photo-fixation">Фото-фиксация</a>
					<a href="/mobile-app#barcode-scanner">Сканер штрих-кодов</a>
					<a href="/mobile-app#government-fines">Штрафы ГИБДД</a>
					<a href="/mobile-app#job-calendar">Календарь поездок</a>
				</NavigationColumn>
				<NavigationColumn>
					<ColumnTitle>Мониторинг</ColumnTitle>
					<a href="/monitoring#terminal">Терминал</a>
					<a href="/monitoring#archive">Архив маршрутных листов</a>
					<a href="/monitoring#log">Журнал событий</a>
					<a href="/monitoring#filials">Поддержка филиалов</a>
					<a href="/monitoring#gps-sensor">GPS мониторинг</a>
					<a href="/monitoring#fuel-sensor">Контроль расхода топлива</a>
					<a href="/monitoring#temp-sensor">Температурный режим</a>
					<a href="/monitoring#door-sensor">Датчик открытия ворот</a>
					<a href="/monitoring#can-bus">CAN шина</a>
					<a href="/monitoring#eco-drive">ECO Drive</a>
				</NavigationColumn>
				<NavigationColumn>
					<ColumnTitle>Маршрутизация</ColumnTitle>
					<a href="/routing#map">Карта</a>
					<a href="/routing#metrics">Просчёт маршрутов</a>
					<a href="/routing#geocode">Определение координат</a>
					<a href="/routing#schedule">График отгрузки ТС</a>
					<a href="/routing#performance">Быстродействие</a>
					<a href="/routing#groupe-use">Групповое использование</a>
					<a href="/routing#lorry">Магистральные перевозки</a>
				</NavigationColumn>
				<NavigationColumn>
					<ColumnTitle>Сервисы</ColumnTitle>
					{/*<a href="/services#analytics">Аналитика</a>*/}
					<a href="/services#fines">Штрафы ГИБДД</a>
					<a href="/services#motivation">Система мотивции</a>
					<a href="/services#customer-links">Ссылка для клиента</a>
				</NavigationColumn>

				<NavigationColumn>
					<ColumnTitle>Тарифы</ColumnTitle>
					<a href="/price#monitoring">Мониторинг</a>
					<a href="/price#mobile-app">Мобильное приложение</a>
					<a href="/price#routing">Маршрутизация</a>
					<a href="/price#services">Сервисы</a>
					<a href="/price#integration">Интеграция</a>

					<ColumnTitle style={{marginTop: 15}}>API</ColumnTitle>
					<a href="/api" style={{paddingTop: 0}}>Руководство</a>
				</NavigationColumn>
			</Navigation>

			<ContactWrapper>
				<ContactContent>
					<Copyright>
						АТС24 — Автоматизация траспортных систем
					</Copyright>
					<SocialMedia>
						<a href="https://t.me/ats24ru" target="_blank">
							<img src="/assets/img/svg/telegram.svg" width={20} height={20}/> @ats24ru
						</a>
					</SocialMedia>
				</ContactContent>
			</ContactWrapper>
		</Wrapper>
	)
}

export default Footer

const Wrapper = styled.div`
  display: flex;
  flex-direction: column;
`
const Navigation = styled.div`
  display: flex;
  width: 100%;
  max-width: 1240px;
  padding-inline: 20px;
  margin-top: 110px;
  align-self: center;
`

const NavigationColumn = styled.div`
  display: flex;
  flex-direction: column;
  flex: 1;

  a {
    color: #FFFFFF85;
    padding-block: 10px;
    font-size: 13px;
    font-weight: 300;
  }

  a:hover {
    color: ${props => props.theme.colorLinkHover};
    transition: .3s ease;
  }
`
const ColumnTitle = styled.div`
  text-transform: uppercase;
  font-size: 13px;
  letter-spacing: 1.1px;
  margin-bottom: 15px;
`
const ContactWrapper = styled.div`
  display: flex;
  flex-direction: column;
  width: 100%;
  margin-top: 60px;
  background-color: ${props => props.theme.colorBgCard};
`
const ContactContent = styled.div`
  display: flex;
  align-items: center;
  width: 100%;
  max-width: 1240px;
  align-self: center;
  padding: 30px 20px;
  font-weight: 300;
`
const Copyright = styled.div`
  flex: 1;
  opacity: .65;
`
const SocialMedia = styled.div`
  a {
    display: flex;
    align-items: center;

    img {
      margin-right: 5px;
    }
  }
`

