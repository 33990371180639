import React from "react"
import styled from "styled-components"
import Icon from "../../../components/Icon/Icon"

const SectionTitle = ({title, sup = null, icon}) => {
	return (
		<Wrapper>
			<PriceHead>
				<Title>
					<IconBlock style={{backgroundColor: icon.backgroundColor}}>
						<Icon name={icon.component} size={icon?.size ? icon.size : 30}/>
					</IconBlock>
					{title}
					{sup && <sup>{sup}</sup>}
				</Title>
				<PayPeriod>
					Стоимость в месяц
				</PayPeriod>
			</PriceHead>
		</Wrapper>
	)
}

export default SectionTitle

const Wrapper = styled.div`
  display: flex;
  flex: 1;
  flex-direction: column;
  margin-top: 110px;
`
const PriceHead = styled.div`
  display: flex;
`
const Title = styled.div`
  display: flex;
  flex: .87;
  align-items: center;
  font-size: 32px;
  font-weight: 600;
  padding-bottom: 32px;

  sup {
    font-size: 12px;
    margin-left: 10px;
    margin-top: -25px;
    color: #FF9500;
    text-transform: uppercase;
  }
`
const PayPeriod = styled.div`
  display: flex;
  flex: .13 .5;
  align-items: flex-end;
  justify-content: center;
  color: #ccced1;
  text-transform: uppercase;
  font-size: 12px;
  padding-bottom: 15px;
`

const IconBlock = styled.div`
  display: flex;
  justify-self: flex-start;
  align-self: flex-start;
  width: 40px;
  height: 40px;
  align-items: center;
  justify-content: center;
  border-radius: 8px;
  margin-right: 20px;
`
