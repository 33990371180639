import styled from "styled-components"
import ContentBlock from "../../components/UI/ContentBlock"
import {Button, Col, Input, Radio, Row, Space} from "antd"

const ContactScreen = () => {

	return (
		<ContentBlock top={120}>
			<ContactWrapper>
				<InfoWrapper>
					<h2>Связаться с нами</h2>
					<p>
						Для быстрого и удобного связи предпочтительно использовать Telegram — <a
						href="https://t.me/ats24ru" target="_blank">@ats24ru.</a>
					</p>

					<p>
						Если вам удобнее использовать телефон или email, заполните, пожалуйста, форму, и мы свяжемся с
						вами в самое короткое время, используя предпочитаемый вами способ связи.
					</p>
				</InfoWrapper>
				<MailWrapper>
					<MailBlock>
						<Title>*Ваш email, телефон или аккаунт в Telegram</Title>
						<Input placeholder="name@domain.ru / +7 xxx xxxxxxx / @telegram_login"/>
					</MailBlock>
					<MailBlock>
						<Row gutter={50}>
							<Col span={12}>
								<Title>Сфера деятельности</Title>
								<Radio.Group>
									<Space direction="vertical" size={15}>
										<Radio value={1}>Продукты питания</Radio>
										<Radio value={2}>Сборные грузы</Radio>
										<Radio value={3}>Бытовые услуги</Radio>
										<Radio value={4}>Заморозка</Radio>
										<Radio value={5}>Магистральные перевозки</Radio>
										<Radio value={6}>другая</Radio>
									</Space>
								</Radio.Group>
							</Col>
							<Col span={12}>
								<Title>Количество точек в сутки</Title>
								<Radio.Group>
									<Space direction="vertical" size={15}>
										<Radio value={1}>10
											<d>—</d>
											50</Radio>
										<Radio value={2}>50
											<d>—</d>
											500</Radio>
										<Radio value={3}>500
											<d>—</d>
											2 000</Radio>
										<Radio value={4}>2 000
											<d>—</d>
											5 000</Radio>
										<Radio value={5}>5 000
											<d>—</d>
											10 000</Radio>
										<Radio value={6}>более 10 000</Radio>
									</Space>
								</Radio.Group>
							</Col>
						</Row>

					</MailBlock>
					<MailBlock>
						<Title>Задачи, которые вы хотите решить</Title>
						<Input.TextArea rows={6} placeholder="Ваше сообщение..."/>
					</MailBlock>
					<Button type="primary" shape="round" size="large">Отправить</Button>
				</MailWrapper>
			</ContactWrapper>
		</ContentBlock>
	)
}

export default ContactScreen

const ContactWrapper = styled.div`
  display: flex;
  flex-wrap: wrap;
`
const InfoWrapper = styled.div`
  flex: 1;
  min-width: 400px;
  padding-top: 25px;

  p {
    font-size: 20px;
    font-weight: 300;
    color: ${props => props.theme.colorTextDescription};
    line-height: 32px;
  }
`
const MailWrapper = styled.div`
  flex: 1;
  min-width: 500px;
  max-width: 700px;
  margin-left: 50px;
  padding: 50px;
  border-radius: 8px;
  background: ${props => props.theme.colorBgCard};
`
const MailBlock = styled.div`
  padding-bottom: 32px;

  d {
    padding-inline: 3px;
  }
`
const Title = styled.div`
  font-size: 12px;
  padding-bottom: 10px;
`
