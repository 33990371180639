import styled from "styled-components"
import {Modal, Button, QRCode, Input, message, Typography} from "antd"
import {CopyOutlined} from "@ant-design/icons"
import {useMemo} from "react"

const ModalDownloadApp = ({selectedStore, setSelectedStore}) => {

	const store = useMemo(() => {
		if (!selectedStore) {
			return {name: 'null', link: 'null'}
		}

		const library = {
			appStore: {
				link: 'https://apps.apple.com/ru/app/id1607517668',
				name: 'AppStore'
			},
			googlePlay: {
				link: 'https://play.google.com/store/apps/details?id=com.twocomrades.ats24&hl=ru',
				name: 'Google Play'
			},
			appGallery: {
				link: 'https://appgallery.huawei.com/#/app/C110185921',
				name: 'AppGallery'
			},
			apk: {
				link: 'https://api.diefly.ru/media/apk',
				name: 'APK файл'
			}
		}

		return library[selectedStore]
	}, [selectedStore])

	return (
		<Modal title={"Установка из " + store.name} open={selectedStore}
			   width={600}
			   zIndex={9}
			   onCancel={() => setSelectedStore(null)}
			   footer={[<Button type="primary" onClick={() => setSelectedStore(null)}>Закрыть</Button>]}
		>
			<p>
				Вы можете установить приложение просто наведя камеру смартфона на QR код.
			</p>
			<QRWrapper>
				<QRCode
					size={256}
					value={store.link}
					viewBox={`0 0 256 256`}
				/>
			</QRWrapper>

			<p>
				Скопируйте ссылку, чтобы отправить её на смартфон.
			</p>

			<Input
				addonAfter={<CopyOutlined onClick={() => {
					navigator.clipboard.writeText(store.link)
					message.open({
						type: 'success',
						content: 'Ссылка скопирована',
					});
				}
				}/>}
				value={store.link}
				onChange={() => null}
			/>

			{selectedStore === 'googlePlay' ?
				<p>
					<Typography.Text type="warning">
						Чтобы открыть GooglePlay, возможно, потребуется VPN.
					</Typography.Text>
				</p> : null
			}
		</Modal>
	)
}

export default ModalDownloadApp

const QRWrapper = styled.div`
  display: flex;
  justify-content: center;
  margin-block: 25px;
`
